import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/localauth';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  changePasswordSuccess,
  changePasswordError,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    
    let assignPermissions = {};
    if (loginUser?.access?.length) {
      assignPermissions = Object.assign({}, ...loginUser.access.map((item) => ({
        [item.gameId]: { [item.permission]: true }
      })));
    } else {
      assignPermissions = {
        defaultGameId: { defaultPermission: true }
      };
    }

    if (loginUser?.code === 200) {
      const item = {
        id: 1,
        title: 'Gamesfi.Live',
        img: '/assets/img/profiles/dp.png',
        date: 'Last seen today 15:24',
        role: 0,
        token: loginUser.token,
        type: loginUser.type,
        access: assignPermissions,
      };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser?.response?.data?.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordChangeEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchChangePassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

const changePasswordAsync = async (oldPassword, newPassword, confirmPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .changePassword(oldPassword, newPassword, confirmPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* changePassword({ payload }) {
  const { oldPassword, newPassword, confirmPassword } = payload;
  try {
    const changePasswordStatus = yield call(
      changePasswordAsync,
      oldPassword, newPassword, confirmPassword
    );
    if (changePasswordStatus?.code === 200) {
      yield put(changePasswordSuccess(changePasswordStatus?.message));
    } else {
      const error = (typeof changePasswordStatus?.response?.data?.message) === 'string' ? changePasswordStatus?.response?.data?.message : changePasswordStatus?.response?.data?.message[0];
      yield put(changePasswordError(error))
    }
  } catch (error) {
    yield put(changePasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchChangePassword),
  ]);
}
