import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    usersFilters: {
        sortByValue: { withdrawal: false },
        orderByValue: 'DESC',
        statesValue: '',
        gameValue: '',
        emailValue: '',
    },
    withdrawalFilters: {
        sortByValue: { date: false, amount: false, usd: false, },
        orderByValue: 'DESC',
        statesValue: '',
        gameValue: '',
        emailValue: '',
        statusValue: '',
        userId: '',
        searchValue: '',
        marketValue: ''
    },
    userDetailFilters: {
        sortByValue: { date: false, amount: false },
        orderByValue: 'DESC',
        statesValue: '',
        gameValue: '',
        emailValue: '',
    },
    scoreFilters: {
        sortByValue: { date: false, amount: false },
        orderByValue: 'DESC',
        gameValue: '',
        emailValue: '',
    },
    gameFilters: {
        statesValue: '',
        gameValue: '',
        categoryValue: '',
    },
    currencyFilters: {
        statesValue: '',
        currencyValue: '',
    },
    categoryFilters: {
        statesValue: '',
        categoryValue: '',
    },
    raffleTemplateFilters: {
        statesValue: '',
        raffleTemplateValue: '',
    },
}

export const counterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        toggleSortByValue: (state, action) => {
            const { forFilter, key } = action.payload
            state[forFilter].sortByValue[key] = !state[forFilter].sortByValue[key]
        },
        setOrderByValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].orderByValue = value
        },
        setStatesValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].statesValue = value
        },
        setStatusValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].statusValue = value
        },
        setSearchValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].searchValue = value
        },
        setGameValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].gameValue = value
        },
        setMarketValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].marketValue = value
        },
        setEmailValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].emailValue = value
        },
        setCurrencyValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].currencyValue = value
        },
        setCategoryValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].categoryValue = value
        },
        setRaffleTemplateValue: (state, action) => {
            const { forFilter, value } = action.payload
            state[forFilter].raffleTemplateValue = value
        },
    },
})

// Action creators are generated for each case reducer function
export const { toggleSortByValue, setOrderByValue, setStatusValue, setStatesValue, setSearchValue, setGameValue, setMarketValue, setEmailValue, setCategoryValue, setCurrencyValue, setRaffleTemplateValue } = counterSlice.actions

export default counterSlice.reducer